var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-vh-100 ba-container d-flex flex-column"
  }, [!_vm.isLoading ? _c('ba-navbar', {
    on: {
      "click-menu": _vm.handleMenuClick
    }
  }) : _vm._e(), _c('div', [_vm.isLoading ? _c('ba-loader-wrapper', {
    staticClass: "bg-transparent vh-100 h-100 w-100 px-0",
    attrs: {
      "isLoading": _vm.isLoading,
      "loading-text": "Please wait, while we put things together..."
    }
  }) : _vm._e(), _c('router-view')], 1), _c('div', [_c('ba-bodadmin-logo', {
    attrs: {
      "dark": ""
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }