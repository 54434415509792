
import Vue from "vue";
// eslint-disable-next-line import/no-cycle
import { mapActions, mapState } from "vuex";

export default Vue.extend({
    components: {},
    data(): {
        isMenuOpen: boolean;
        isLoading: boolean;
    } {
        return {
            isMenuOpen: false,
            isLoading: true,
        };
    },
    computed: {
        ...mapState("reports", ["reports"]),
    },
    methods: {
        ...mapActions({
            fetchUsers: "users/fetchUsers",
            fetchLevels: "users/fetchLevels",
            fetchProfile: "auth/fetchProfile",
            // fetchClaims: "auth/fetchClaims",
            fetchCompanyProfile: "company/fetchProfile",
            fetchReportList: "reports/fetchReportList",
        }),
        handleMenuClick(e: any) {
            e.preventDefault();
            e.stopPropagation();
            (this.$refs.slide as any).$children[0].openMenu();
            this.isMenuOpen = true;
        },
        closeMenu() {
            (this.$refs.slide as any).$children[0].closeMenu();
        },
        async setupApp() {
            try {
                document.body.classList.add("overflow-hidden");
                this.isLoading = true;
                await this.fetchUsers();
                await this.fetchLevels();
                await this.fetchProfile();
                await this.fetchCompanyProfile();
                // await this.fetchClaims();
                if (this.reports.length === 0) {
                    await this.fetchReportList();
                }
            } catch (e) {
                this.isLoading = false;
                this.$bvToast.toast("Error", { title: "Error while fetching data!", variant: "danger", solid: true });
            } finally {
                this.isLoading = false;
                document.body.classList.remove("overflow-hidden");
            }
        },
    },
    watch: {},
    async mounted() {
        await this.setupApp();
    },
});
